





























































































































import axios from 'axios';
import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { Connection, ConnectionCategory, ConnectionStatus, Providers } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTruncateText from '@/components/ui/UiTruncateText.vue';
import Beta from '@/components/util/Beta.vue';
import BetaProvideFeedback from '@/components/util/BetaProvideFeedback.vue';
import { InactivityWatcher } from '@/inactivityWatcher';
import { MUT_SNACKBAR } from '@/store';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

@Component({
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      update(data: { connections?: Connection[] }) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
  components: {
    UiButton,
    Beta,
    BetaProvideFeedback,
    UiDatePicker,
    UiSelect2,
    UiDataTable,
    UiTruncateText,
    UiPagination,
    UiPageHeader,
  },
})
export default class ExpandedJournalReport extends BaseVue {
  public isLoading = false;
  public fromDate = '';
  public toDate = '';
  public selectedConnectionId = '';
  public connections: Connection[] = [];
  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;
  public apiBaseUrl = process.env.VUE_APP_API_URL;
  public reports = [];
  public page = 1;
  public itemsPerPage = 10;
  public sort = { id: 'createdOn', asc: false };
  public reportElapsedTime? = '';

  public get connectionList() {
    const categories = this.$store.getters['categories/CATEGORIES'];
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];

    let connections = this.connections;

    if (
      (contacts?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false) ||
      (categories?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false)
    ) {
      const manualAccountingConnection = {
        id: 'Manual',
        provider: Providers.Manual,
        status: ConnectionStatus.Ok,
      };
      connections = connections.concat(manualAccountingConnection);
    }

    if (connections && connections.length > 0) {
      return connections;
    } else {
      return [{ provider: 'None', id: 'none' }];
    }
  }

  public get headers() {
    return [
      {
        id: 'id',
        label: 'Id',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'startDate',
        label: 'Start Date',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'endDate',
        label: 'End Date',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'connection',
        label: 'Connection',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'createdBy',
        label: 'Created By',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'createdOn',
        label: 'Created Date',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'download',
        label: 'Download',
        defaultVisibility: true,
        sortable: false,
      },
    ];
  }

  public get filteredReports() {
    const sort = this.sort;
    const reports = this.reports.sort((a: any, b: any) => {
      let aValue;
      let bValue;
      switch (sort.id) {
        case 'startDate':
          aValue = a.config.startDateTimeSEC;
          bValue = b.config.startDateTimeSEC;
          break;
        case 'endDate':
          aValue = a.config.endDateTimeSEC;
          bValue = b.config.endDateTimeSEC;
          break;
        case 'connection':
          aValue = a.config.connectionId;
          bValue = b.config.connectionId;
          break;
        case 'createdBy':
          aValue = a.createdBy.email;
          bValue = b.createdBy.email;
          break;
        case 'status':
          aValue = a.status;
          bValue = b.status;
          break;
        case 'createdOn':
          aValue = a.createdSEC;
          bValue = b.createdSEC;
          break;
        default:
          aValue = a[sort.id];
          bValue = b[sort.id];
          break;
      }
      // if asc is true, then we want to sort in ascending order
      // with a secondary sort on a.createdSEC and b.createdSEC descending
      if (aValue === bValue) {
        return a.createdSEC > b.createdSEC ? -1 : 1;
      }
      if (sort.asc) {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    return this.reports.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
  }

  public onSort(sort: any = { id: 'createdOn', asc: false }) {
    this.$set(this, 'sort', sort);
  }

  public async runReport() {
    this.isLoading = true;
    this.reportElapsedTime = undefined;
    const reportStartTime = Date.now();

    try {
      const dataUrl =
        this.apiBaseUrl +
        'reports/view?type=JournalEntrySaveDownReport&orgId=' +
        this.$store.state.currentOrg.id +
        '&startDate=' +
        this.fromDate +
        '&endDate=' +
        this.toDate +
        '&connectionId=' +
        this.selectedConnectionId;
      const response = await axios.get(dataUrl, {
        withCredentials: true,
      });
      if (response.status === 200) {
        await this.loadReports();
      }
    } finally {
      this.isLoading = false;
      this.reportElapsedTime = this.getElapsedTime(reportStartTime);
    }
  }

  public getProviderById(id: string) {
    return this.connectionList.find((x) => x.id === id)?.provider ?? '';
  }

  public async loadReports() {
    this.isLoading = true;
    try {
      const dataUrl =
        this.baseUrl + 'v2/orgs/' + this.$store.state.currentOrg.id + '/reports/?type=journal-entry-save-down';
      const response = await axios.get(dataUrl, {
        withCredentials: true,
      });
      if (response.status === 200) {
        this.reports = response.data.items;
      }
    } finally {
      this.isLoading = false;
    }
  }

  public async refresh() {
    await this.loadReports();
  }

  isValidDate(dateString: string) {
    // Parse the date string into a Date object
    var dateObj = new Date(dateString);

    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  get isValid() {
    return this.isValidDate(this.fromDate) && this.isValidDate(this.toDate);
  }

  public async downloadCSV(runId: string) {
    this.isLoading = true;

    const frames = (this.$refs.reportsContainer as Element)?.querySelectorAll('iframe.temp-iframe');
    if (frames?.length) {
      (this.$refs.reportsContainer as Element)?.removeChild(frames[0]);
    }

    requestParentToKeepActive('report', true);
    const inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
      () => this.isLoading && document.contains(this.$el)
    );
    try {
      const exportUrl =
        this.baseUrl + 'v2/orgs/' + this.$store.state.currentOrg.id + '/reports/' + runId + '?includeDownloadUrls=true';

      const resp = await axios({ method: 'get', url: exportUrl, withCredentials: true });
      if (resp.status === 200) {
        const href = resp.data.links.results.href;
        this.downloadFile(href);
      } else {
        this.showErrorMessage();
      }
    } finally {
      this.isLoading = false;
      requestParentToKeepActive('report', false);
      inactivityWatcherKeepActive?.dispose();
    }
  }

  async downloadFile(link: string) {
    // create the iframe element
    const iframe = document.createElement('iframe');
    iframe.classList.add('temp-iframe');
    // set the src attribute of the iframe
    iframe.src = link;
    // set the style of the iframe to display:none
    iframe.style.display = 'none';
    // append the iframe to the container element
    (this.$refs.reportsContainer as Element)?.appendChild(iframe);
    this.showSuccessMessage();
  }

  showErrorMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'error',
      message: 'Failed to export. Try again later',
    });
  }

  showSuccessMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'success',
      message: 'File download completed',
    });
  }

  async mounted() {
    await this.refresh();
    this.onSort();
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    await this.loadReports();
  }
}
